import { useCallback, useMemo, useRef, useState } from 'react'
import { toastr } from 'react-redux-toastr'

import { useMutation } from '@tanstack/react-query'
import { FormHandles, Scope } from '@unform/core'
import { Form } from '@unform/web'

import AlertInfo from '~/components/AlertInfo'
import { Button } from '~/components/Buttons'
import NameAndTimeInterval from '~/components/unform/FormGroup/NameAndTimeInterval'
import { handleValidationsErrorsAPI, history } from '~/helpers'
import { useAppInfo } from '~/hooks/useAppInfo'
import { useErrorApiAlert } from '~/hooks/useErrorApiAlert'
import useQueryParams from '~/hooks/useQuery'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import {
  createCampaignKey,
  createCampaignService
} from '~/modules/retailMedia/services/campaign'
import { useAppSelector } from '~/store/hooks'

import ProductsPanel from '../../../ProductsPanel'
import AdvancedSettings from '../../elements/Advanced'
import { Banner } from '../../elements/Banner'
import Budget from '../../elements/Budget'
import CampaignSettings from '../../elements/CampaignSettings'
import CampaignTargeting from '../../elements/CampaignTargeting'
import DailyCostScope from '../../elements/DailyCostScope'
import { useCampaignForm } from '../../hooks/useCampaignForm'
import * as S from '../../styles'
import { DTOCreateBannerCampaign } from './dto'

type CampaignTargetingState =
  | undefined
  | {
      targeting: RetailMediaCampaignSegmentation
      data: { keywords: string[] | null; categories: Tag[] | null }
    }

const BannerFields: React.FC = () => {
  const formRef = useRef<FormHandles>(null)

  const [loading, setLoading] = useState(false)
  const [settingBannerIsValid, setSettingBannerIsValid] = useState(true)
  const [mediaBannerIsValid, setMediaBannerIsValid] = useState(true)
  const [bannersToTable, setBannersToTable] = useState([])
  const [budgetValueIsValid, setBudgetValueIsValid] = useState(false)
  const [campaignTargeting, setCampaignTargeting] =
    useState<CampaignTargetingState>()

  const { t } = useAPPTranslation()

  const rmid = useQueryParams().get('rmid')

  const { costType, audienceId } = useCampaignForm()

  /**
   * Form is ready
   */
  const formIsValid = useMemo(() => {
    const bannerMediaIsValid = !!(
      !!mediaBannerIsValid && !!bannersToTable?.length
    )

    const bannerSettingsIsValid = !!settingBannerIsValid

    const bannerSettingIsValidMessage: StringNull = !bannerSettingsIsValid
      ? t('rm:campaign.create.alerts.leastOneDisplayConfiguration')
      : null

    const hasAtLeastOneBannerMessage: StringNull = !bannerMediaIsValid
      ? t('rm:campaign.create.alerts.leastOneBanner')
      : null

    const messages = [
      bannerSettingIsValidMessage,
      hasAtLeastOneBannerMessage
    ].filter(item => !!item)

    return {
      isValid: bannerMediaIsValid && bannerSettingsIsValid,
      messages
    }
  }, [bannersToTable?.length, mediaBannerIsValid, settingBannerIsValid, t])

  const formDataFinal = useMemo(() => {
    const getTargeting = () => {
      if (campaignTargeting?.targeting === 'category') {
        return {
          categories: String(
            campaignTargeting?.data?.categories.map(item => item.id)
          )
        }
      }

      if (campaignTargeting?.targeting === 'keyword') {
        return {
          keywords: String(campaignTargeting?.data?.keywords)
        }
      }

      return {}
    }

    return {
      name: 'name',
      start_date: '2024-23-23',
      type: 'on_site',
      banners: bannersToTable,
      targeting: getTargeting()
    }
  }, [bannersToTable, campaignTargeting])

  /**
   * Form is ready
   * Valor para controlarmos se devemos bloquear o submit
   */
  const formIsReadyToSubmit = useMemo(
    () => costType === 'daily_cost' || budgetValueIsValid,
    [budgetValueIsValid, costType]
  )

  /**
   * Handle products
   */
  const {
    campaign: {
      products: { selectecProducts }
    }
  } = useAppSelector(state => state.retailMedia)

  /**
   * Handle request
   */
  const [AlertErrorCampaign, setErrorCampaign] = useErrorApiAlert({
    title: t('rm:campaign.create.toast.error.message')
  })

  const createCampaign = useMutation({
    mutationKey: [createCampaignKey],
    mutationFn: createCampaignService,
    onSuccess: response => {
      setLoading(false)

      history.push(`/retail-media/campaign/view/${response.data.id}`)

      toastr.success(
        t('rm:campaign.create.toast.success.title'),
        t('rm:campaign.create.toast.success.message')
      )
    },
    onError: error => {
      setLoading(false)

      setErrorCampaign(error)

      handleValidationsErrorsAPI(error, formRef)

      toastr.error(
        t('rm:campaign.create.toast.error.title'),
        t('rm:campaign.create.toast.error.message')
      )
    }
  })

  /**
   * Handle submit
   */
  const { lengthCentsCurrency } = useAppInfo()

  const handleSubmit = useCallback(
    async (formData: CreateCampaignBannerFormData) => {
      const products = selectecProducts?.map(item => ({
        id: item?.id,
        sku: item?.product_sku || item?.sku
      }))

      const preData = {
        ...formData,
        products,
        banners: bannersToTable,
        publisher_id: rmid,
        lengthCentsCurrency
      }

      const body = DTOCreateBannerCampaign(preData)

      setLoading(true)

      await createCampaign.mutateAsync(body)
    },
    [
      bannersToTable,
      createCampaign,
      lengthCentsCurrency,
      rmid,
      selectecProducts
    ]
  )

  /**
   * Initial data
   */
  const initialData: Partial<CreateCampaignBannerFormData> = {
    budget: { daily: null, cpc: null, cpm: null },
    placements: { placementsCheck: 'all' },
    targeting: { audienceToggleWrapper: { audienceToggle: !!audienceId } },
    settings: {
      budget_type: 'fast_as_possible'
    },
    cost: {
      cost_type: 'cpmcpc'
    }
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit} initialData={initialData}>
      <NameAndTimeInterval
        formRef={formRef}
        labelTitle={t('rm:campaign.create.NameAndTimeInterval.labelTitle')}
        placeholderTitle={t(
          'rm:campaign.create.NameAndTimeInterval.placeholderTitle'
        )}
        hasDescription
        labelDescription={t(
          'rm:campaign.create.NameAndTimeInterval.labelDescription'
        )}
        placeholderDescription={t(
          'rm:campaign.create.NameAndTimeInterval.placeholderDescription'
        )}
        onlyDates
        endDateIsRequired={false}
        showValidBadgeOnSection
        intervalAlertMessage={[
          t('rm:campaign.create.NameAndTimeInterval.intervalAlertMessage')
        ]}
        showEndDateOptional
        titlePanel={t('rm:campaign.create.NameAndTimeInterval.titlePanel')}
      />

      <CampaignTargeting
        handleSectionValidity={setSettingBannerIsValid}
        onChange={setCampaignTargeting}
        formRef={formRef}
      />

      {!!campaignTargeting?.targeting && (
        <>
          <Scope path="banner">
            <Banner
              formRef={formRef}
              bannersToTable={bannersToTable}
              setBannersToTable={setBannersToTable}
              handleSectionValidity={setMediaBannerIsValid}
              selectedSegmentation={campaignTargeting?.targeting}
              initialData={{ publisher_id: rmid }}
            />
          </Scope>

          <CampaignSettings />

          <ProductsPanel
            formRef={formRef}
            description={t(
              'rm:campaign.create.ProductsPanel.description.relate'
            )}
            initialData={{ publisher_id: rmid }}
          />

          {!!formIsValid.messages?.length && (
            <S.WrapperAlerts>
              {formIsValid.messages.map((message, index) => (
                <AlertInfo
                  text={message?.text || message}
                  key={index}
                  onClick={message?.action}
                  role={message?.action ? 'button' : 'text'}
                />
              ))}
            </S.WrapperAlerts>
          )}

          <hr />

          <AdvancedSettings targeting={campaignTargeting.targeting} />

          {costType === 'cpmcpc' && (
            <Budget
              adType="banner"
              publisherId={rmid}
              formData={formDataFinal}
              formIsValid={formIsValid}
              handleIsValid={setBudgetValueIsValid}
              initialValueDailyBudget={'R$ 100.00'}
            />
          )}

          {costType === 'daily_cost' && <DailyCostScope />}

          <AlertErrorCampaign />

          <div className="form-default__buttons">
            <Button
              type="submit"
              text="Enviar para aprovação"
              loading={loading}
              size="large"
              template="success"
              disabled={!formIsReadyToSubmit}
            />
          </div>
        </>
      )}
    </Form>
  )
}

export default BannerFields
