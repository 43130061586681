export const DTOCreateSponsoredBrandsCampaign = (
  data: DTOCreateSponsoredBrandsCampaignPayload
) => {
  /**
   * Handle sponsored brand config
   */
  const destinationUrls = Object.entries(data.settings?.destination_urls || {})

  const formattedDestination = destinationUrls.map(([brand, url]) => ({
    brand,
    url
  }))

  const sponsoredBrandData = {
    keywords: data.targeting?.keywords?.split(',') || null,
    categories: data.targeting?.categories?.split(',') || null,
    destinations: formattedDestination
  }

  /**
   * Handle products linkded to campaign
   */

  const products =
    data?.products?.map(item => ({
      id: item.id ? String(item.id) : undefined,
      sku: item.sku ? String(item.sku) : undefined
    })) || null

  /**
   * Handle budget
   */
  const { lengthCentsCurrency } = data

  const cpcRaw = data.budget.cpc.numAsString
  const cpc = lengthCentsCurrency ? Number(cpcRaw) / 100 : Number(cpcRaw)

  const cpmRaw = data.budget.cpm.numAsString
  const cpm = lengthCentsCurrency ? Number(cpmRaw) / 100 : Number(cpmRaw)

  const dailyBudgetRaw = data.budget.daily.numAsString
  const dailyBudget = lengthCentsCurrency
    ? Number(dailyBudgetRaw) / 100
    : Number(dailyBudgetRaw)

  const budget = {
    daily_budget: dailyBudget,
    cpc,
    cpm
  }

  /**
   * Handle campaign settings
   */

  const settings: DTOCreateSponsoredBrandsCampaignSettings = {
    ...budget,
    headline: data?.sponsored_brand?.headline,
    description: data?.sponsored_brand?.description,
    brand_url: data?.sponsored_brand?.brand_url,
    brand_name: data?.sponsored_brand?.brand_name,
    assets: data?.sponsored_brand?.assets,
    products
  }

  if (data?.placements?.placementsCheck === 'selected') {
    const placements = data?.placements?.placements_ids
    const selectedPlacements = Object.values(placements).filter(
      values => values.checked
    )

    settings.placements = selectedPlacements.map(({ value: id }) => ({ id }))
  }

  if (data?.settings?.budget_type) {
    settings.budget_type = data.settings.budget_type
  }

  const body: DTOCreateSponsoredBrandsCampaignResponse = {
    publisher_id: data?.publisher_id,

    name: data.name,
    description: data?.description,

    type: 'on_site',
    ad_type: 'sponsored_brand',

    start_at: data.start_date,
    end_at: data?.end_date || null,

    brand_ad: { ...sponsoredBrandData },

    settings
  }

  return body
}
